import type { Swiper as SwiperClass } from 'swiper';

import { AppLink } from 'types/applink';
import { BannerZonePlatform } from './BannerZones/constants';

export const loadPrevNextImages = (swiper: SwiperClass): void => {
    swiper.slides.forEach((slide) => {
        if (
            slide.classList.contains('swiper-slide-next') ||
            slide.classList.contains('swiper-slide-prev')
        ) {
            const image = slide.querySelector('img');

            if (!image || image.loading !== 'lazy') {
                if (image?.complete) {
                    const loader = slide.querySelector(
                        '.swiper-lazy-preloader'
                    );

                    if (loader) {
                        (loader as HTMLDivElement).style.display = 'none';
                    }
                }

                return;
            }

            image.setAttribute('loading', 'eager');
        }
    });
};

export const onInit = (swiper: SwiperClass): void => {
    const timeoutId = setTimeout(() => {
        loadPrevNextImages(swiper);
    }, 300);

    swiper.once('destroy', () => {
        clearTimeout(timeoutId);
    });
};

export const getTopPromoBannerPlatform = (
    isMobile: boolean
): Array<BannerZonePlatform> => {
    if (isMobile) return [BannerZonePlatform.MOBILE];

    return [BannerZonePlatform.DESKTOP];
};

export const getTopSliderPages = [
    AppLink.BETS,
    AppLink.LIVE,
    AppLink.NEXTTOGO,
    AppLink.SPORTS,
    AppLink.ESPORTS,
];
