import { FC, memo, useCallback } from 'react';
import cn from 'classnames';
import { useSwiper } from 'swiper/react';

import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import { ControlButtonDirection } from './constants';

interface Props {
    type: ControlButtonDirection;
    controlClassName?: string;
    controlIconClassName?: string;
    controlIconSize?: SvgIconSize;
    activeIndex: number;
    slidesLength: number;
    loop: boolean;
    dataTest?: string;
}

const ControlButton: FC<Props> = ({
    type,
    controlClassName,
    controlIconClassName,
    controlIconSize = SvgIconSize.WH_3,
    activeIndex,
    slidesLength,
    loop,
    dataTest,
}) => {
    const swiper = useSwiper();

    const nextStyles =
        'mr-2 right-0 translate-x-[60%] rounded-full justify-start pl-2';
    const prevStyles =
        'ml-2 left-0 translate-x-[-60%] rounded-full justify-end pr-2';

    const handleSwipe = useCallback(() => {
        if (type === ControlButtonDirection.Next) {
            return swiper.slideNext();
        }

        swiper.slidePrev();
    }, [swiper, type]);

    const isDisabledButton =
        !loop &&
        ((type === ControlButtonDirection.Next &&
            activeIndex === slidesLength - 1) ||
            (type === ControlButtonDirection.Prev && activeIndex === 0));

    return (
        <div
            onClick={handleSwipe}
            className={cn(
                'absolute top-[50%] z-10 flex h-14 w-14 translate-y-[-50%] cursor-pointer items-center [&>*]:hover:fill-primary-orange-toxic',
                type === ControlButtonDirection.Next ? nextStyles : prevStyles,
                controlClassName,
                {
                    '!cursor-default [&>*]:hover:fill-surface-light':
                        isDisabledButton,
                }
            )}
            data-test={dataTest}
        >
            <PackIcon
                id="arrow-left"
                pack={IconsPack.SpriteIcons}
                size={controlIconSize}
                className={cn(
                    type === 'next' && 'rotate-180',
                    controlIconClassName,
                    {
                        'fill-surface-light cursor-default': isDisabledButton,
                    }
                )}
            />
        </div>
    );
};

export default memo(ControlButton);
