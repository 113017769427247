import React from 'react';
import cn from 'classnames';

import OpacityWrapper from 'components/OpacityWrapper';
import useStartMatchData from './useStartMatchData';

enum Size {
    Default = 'Default',
    Small = 'Small',
}

const timeCn = {
    [Size.Default]: cn('mb-2 typo-md-2'),
    [Size.Small]: cn('mb-1 typo-sm-2 md:mb-2 md:typo-md-2'),
};

const dateCn = {
    [Size.Default]: cn('whitespace-nowrap typo-sm-1'),
    [Size.Small]: cn('mb-1 whitespace-nowrap typo-xs-1 md:mb-0 md:typo-sm-1'),
};

interface Props {
    startTime: string;
    classNameTimeSector?: string;
    size?: Size;
    dateFormat?: string;
}

function StartTimeAndDate({
    startTime,
    size = Size.Default,
    classNameTimeSector,
    dateFormat = 'dd.MM.yy',
}: Props): JSX.Element {
    const { time, date } = useStartMatchData(startTime, {
        dateFormat,
    });

    return (
        <OpacityWrapper>
            {(opacityClassName) => (
                <div
                    className={cn(
                        'flex flex-col items-center',
                        opacityClassName
                    )}
                >
                    <div
                        className={cn(
                            'mb-2 flex h-10 items-center',
                            timeCn[size],
                            classNameTimeSector
                        )}
                    >
                        {time}
                    </div>
                    <div className={dateCn[size]}>{date}</div>
                </div>
            )}
        </OpacityWrapper>
    );
}

StartTimeAndDate.Size = Size;

export default StartTimeAndDate;
