import { FC, memo } from 'react';
import cn from 'classnames';

import { Sizes as CompetitorSizes } from 'components/betting/Competitor/Competitor';
import Competitors from 'components/betting/Competitors';
import StartTimeAndDate from 'components/betting/Match/ScoreBoard/StartTimeAndDate';
import OddButton from 'components/betting/OddButton';
import SmartLink from 'components/SmartLink';
import idPathParser from 'utils/idPathParser';
import { BannerThemes, BannerThemesType } from '../../types';
import { sportBackgroundIconMap, sportNameBackgroundMap } from '../constants';
import type { SportEventBannerItem, TotalScore } from '../types';
import useSportEventBanner from './useSportEventBanner';

const WrapperTheme: BannerThemesType = {
    [BannerThemes.TopSlider]: cn('pb-4 pt-2'),
    [BannerThemes.TopPromoTournamentSlider]: cn('pb-2 pt-0 md:pb-4 md:pt-2'),
};

const OddButtonSizeTheme = {
    [BannerThemes.Default]: OddButton.Size.Default,
    [BannerThemes.TopSlider]: OddButton.Size.Default,
    [BannerThemes.TopPromoTournamentSlider]: OddButton.Size.Small,
};

const OddButtonColorTheme = {
    [BannerThemes.Default]: OddButton.Color.Dark,
    [BannerThemes.TopSlider]: OddButton.Color.Light,
    [BannerThemes.TopPromoTournamentSlider]: OddButton.Color.Dark,
};

const TimeAndDateTheme = {
    [BannerThemes.Default]: StartTimeAndDate.Size.Default,
    [BannerThemes.TopSlider]: StartTimeAndDate.Size.Default,
    [BannerThemes.TopPromoTournamentSlider]: StartTimeAndDate.Size.Small,
};

const CompetitorTheme = {
    [BannerThemes.Default]: CompetitorSizes.Default,
    [BannerThemes.TopSlider]: CompetitorSizes.Default,
    [BannerThemes.TopPromoTournamentSlider]: CompetitorSizes.Small,
};

interface Props {
    item: SportEventBannerItem;
    bannerTheme?: BannerThemes;
}

const SportEventBanner: FC<Props> = ({
    item,
    bannerTheme = BannerThemes.Default,
}) => {
    const {
        isLive,
        linkToMatch,
        isTopPromoTournament,
        bgContainerStyles,
        sportId,
        scoreBoardCompetitors,
        market,
        startTime,
        totalScore,
        sportType,
        isMobile,
    } = useSportEventBanner({ item, bannerTheme });

    const { sportevent, id } = item;
    const { sport } = sportevent.fixture;
    const [homeCompetitor, awayCompetitor] = scoreBoardCompetitors;

    const bannerIconBackground =
        sportBackgroundIconMap[sportId] || sportBackgroundIconMap[sportType];
    const sportNameBackground =
        sportNameBackgroundMap[sportId] || sportNameBackgroundMap[sportType];

    const isTopSliderBannerDesktop = !isTopPromoTournament && !isMobile;

    const marketOddButtonsJSX = (
        <div
            className={cn(
                'flex h-10 w-full gap-1 md:h-12',
                isLive ? 'md:mt-2' : 'md:mt-1',
                {
                    'md:min-w-[290px]': !isTopPromoTournament,
                }
            )}
        >
            {market &&
                market.odds?.map((odd) => (
                    <OddButton
                        key={idPathParser.join('Odd', {
                            oddId: odd.id,
                            sportEventId: id,
                            marketId: market.id,
                        })}
                        odd={odd}
                        classNames="max-h-10 md:not-last:mr-1 py-1 px-2"
                        market={market}
                        size={OddButtonSizeTheme[bannerTheme]}
                        oddsCount={market.odds?.length}
                        color={OddButtonColorTheme[bannerTheme]}
                        gap={bannerTheme === BannerThemes.TopSlider ? 1 : 2}
                        isResizable={false}
                    />
                ))}
        </div>
    );

    return (
        <SmartLink
            href={linkToMatch}
            className={cn('w-full', {
                'h-[128px] md:h-[176px]': isTopPromoTournament,
            })}
        >
            <div className={bgContainerStyles}>
                <div className="relative z-1">
                    {!isTopPromoTournament && (
                        <div
                            className={cn(
                                'mx-auto flex h-6 max-w-fit items-center justify-center px-2',
                                sportNameBackground
                            )}
                        >
                            <span className="border-b-[4px] border-transparent font-klapt uppercase typo-md-2">
                                {sport.name}
                            </span>
                        </div>
                    )}
                    <div
                        className={cn(
                            'flex flex-col items-center justify-center rounded-b-default bg-transparent'
                        )}
                    >
                        <div
                            className={cn(
                                'flex w-full items-center justify-between',
                                bannerTheme ? WrapperTheme[bannerTheme] : ''
                            )}
                        >
                            <Competitors
                                home={homeCompetitor}
                                away={awayCompetitor}
                                sportId={sportId}
                                displayType="column"
                                size={CompetitorTheme[bannerTheme]}
                                competitorCn={cn({
                                    '-mt-12': isTopSliderBannerDesktop,
                                })}
                            >
                                <div className="text-center">
                                    {isLive ? (
                                        <Score totalScore={totalScore} />
                                    ) : (
                                        <StartTimeAndDate
                                            classNameTimeSector={cn({
                                                'md:!h-4 !mb-1':
                                                    !isTopPromoTournament,
                                            })}
                                            startTime={startTime}
                                            dateFormat="MMM dd"
                                            size={TimeAndDateTheme[bannerTheme]}
                                        />
                                    )}
                                    {isTopSliderBannerDesktop &&
                                        marketOddButtonsJSX}
                                </div>
                            </Competitors>
                        </div>
                        {!isTopSliderBannerDesktop && marketOddButtonsJSX}
                    </div>
                </div>
                {!isTopPromoTournament && (
                    <div
                        className="absolute left-0 top-0 h-full w-full bg-auto bg-center bg-no-repeat"
                        style={{
                            backgroundImage: `url(/spa-assets/image/sport-icons/${bannerIconBackground})`,
                        }}
                    />
                )}
            </div>
        </SmartLink>
    );
};

const Score: FC<{ totalScore: TotalScore }> = ({ totalScore }) => {
    const { home, away } = totalScore;

    return (
        <div className="my-1 py-4 !font-bold typo-lg-2 md:my-0 md:py-0">
            <span>{home}</span>
            <span className="mx-3 md:mx-4">:</span>
            <span>{away}</span>
        </div>
    );
};

export default memo(SportEventBanner);
