import { FC } from 'react';

import { SportEventsInList } from 'types';
import { BannerThemes } from '../types';
import CmsBanner from './CmsBanner';
import SportEventBanner from './SportEventBanner';
import type { Banner as BannerUnion, CmsBannerOptions } from './types';

interface Props {
    banner: BannerUnion | SportEventsInList[number];
    options?: CmsBannerOptions;
    bannerTheme?: BannerThemes;
}

const Banner: FC<Props> = ({ banner, options, bannerTheme }) => {
    switch (banner.__typename) {
        case 'DecodedBannerImpl': {
            return <CmsBanner banner={banner} options={options} />;
        }

        case 'BannerBettingSportEvent': {
            return <SportEventBanner item={banner} bannerTheme={bannerTheme} />;
        }

        case 'SportEvent': {
            const sportEventBanner = {
                __typename: banner.__typename,
                id: banner.id,
                sportevent: { ...banner },
            };

            return (
                <SportEventBanner
                    item={sportEventBanner}
                    bannerTheme={bannerTheme}
                />
            );
        }

        default: {
            return null;
        }
    }
};

export default Banner;
