import { first } from 'lodash';

import useBanners from 'components/betting/Promo/Banners/useBanners';
import { useGetBannerZones } from 'gql/betting/queries/getBannerZones.bet';
import { BannerZoneAttributeFilters } from 'types/gql.bet';
import { Banner } from './Banners/types';
import { useBannerZones } from './BannerZones';

type QueryOptions = ReturnType<
    NonNullable<Parameters<typeof useGetBannerZones>[0]>
>;

type Input = Omit<QueryOptions, 'variables'> & {
    variables?: Pick<BannerZoneAttributeFilters, 'pageLocations' | 'platforms'>;
};

interface Output {
    loading: boolean;
    banners: Banner[];
    delay: number;
    maxCount?: string;
    bg?: string;
}

const useBannerZoneData = (options: Input): Output => {
    const { pageLocations, platforms } = options.variables || {};

    const { data: bannerZoneData, loading: bannerZonesLoading } =
        useBannerZones({
            variables: { pageLocations, platforms },
            fetchPolicy: options.fetchPolicy,
        });

    const zone = first(bannerZoneData?.bannerZones);

    const { banners = [], loading: bannerLoading } = useBanners({
        zoneId: zone?.id,
    });

    const delay = Number(
        zone?.settings?.find(({ name }) => name === 'interval')?.value ||
            '10000'
    );

    const bg = zone?.settings?.find(({ name }) => name === 'backgroundColor')
        ?.value;

    const maxCount = zone?.settings?.find(({ name }) => name === 'maxCount')
        ?.value;

    return {
        bg,
        delay,
        maxCount,
        banners,
        loading: bannerZonesLoading || bannerLoading,
    };
};

export default useBannerZoneData;
