import { FC, memo, PropsWithChildren } from 'react';
import cn from 'classnames';

import Competitor, { CompetitorProps } from '../Competitor';

interface HomeAway
    extends Omit<CompetitorProps, 'sportId' | 'displayType' | 'size'> {}

interface Props extends PropsWithChildren {
    sportId: string;
    home: HomeAway;
    away: HomeAway;
    competitorCn?: string;
    displayType?: CompetitorProps['displayType'];
    size?: CompetitorProps['size'];
    maxLinesForName?: CompetitorProps['maxLinesForName'];
}

const Competitors: FC<Props> = ({
    children,
    sportId,
    home,
    away,
    displayType,
    size,
    maxLinesForName,
    competitorCn,
}) => {
    const competitorClassName = cn('grow', competitorCn);

    return (
        <>
            <Competitor
                {...home}
                displayType={displayType}
                sportId={sportId}
                className={competitorClassName}
                size={size}
                maxLinesForName={maxLinesForName}
            />
            <div
                className="flex w-auto flex-col items-center text-primary-white"
                data-test="competitors"
            >
                {children}
            </div>
            <Competitor
                {...away}
                displayType={displayType}
                sportId={sportId}
                className={competitorClassName}
                size={size}
                maxLinesForName={maxLinesForName}
            />
        </>
    );
};

export default memo(Competitors);
