import { fillLink } from 'cl-fill-link';
import cn from 'classnames';

import { AppLink, SportType } from 'app-constants';
import type { ScoreBoardCompetitor } from 'components/betting/Match/ScoreBoard/types';
import { useBettingLayout } from 'layouts/BettingLayout/BettingLayoutContext';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { SportEventsInList } from 'types';
import { SportEventStatus } from 'types/gql.bet';
import {
    getBackgroundColor,
    getHomeAwayCompetitors,
    getTotalScore,
} from 'utils';
import { BannerThemes } from '../../types';
import type {
    BettingSportEventBanner,
    SportEventBannerItem,
    TotalScore,
} from '../types';

type CurrentMarket =
    | BettingSportEventBanner['sportevent']['markets'][number]
    | SportEventsInList[number]['markets'][number]
    | undefined;

interface Input {
    item: SportEventBannerItem;
    bannerTheme?: BannerThemes;
}

interface Output {
    isLive: boolean;
    linkToMatch: string;
    isTopPromoTournament: boolean;
    bgContainerStyles: string;
    sportId: string;
    startTime: string;
    totalScore: TotalScore;
    market: CurrentMarket;
    scoreBoardCompetitors: Array<ScoreBoardCompetitor>;
    sportType: SportType;
    isMobile: boolean;
}

const useSportEventBanner = ({ item, bannerTheme }: Input): Output => {
    const { sportevent } = item;
    const { sportId, competitors, startTime, status } = sportevent.fixture;
    const { sports } = useBettingLayout();
    const { sportType } = sports[sportId];
    const isMobile = useResponsive().until('md');
    const market: CurrentMarket = sportevent.markets[0];

    const bgColor = getBackgroundColor(sportId, sportType, item.__typename);

    const isTopPromoTournament =
        bannerTheme === BannerThemes.TopPromoTournamentSlider;

    const bgContainerStyles = cn(
        'relative z-10 mx-auto h-full w-full p-2 md:p-4',
        { 'bg-radial-gradient-b-small': !isTopPromoTournament },
        // TODO: ask about sport default color
        bgColor || 'from-primary-orange-toxic'
    );

    const linkBySportType =
        sportType === SportType.Esports
            ? AppLink.ESPORTS_MATCH_SLUG
            : AppLink.SPORTS_MATCH_SLUG;

    const linkToMatch = fillLink(linkBySportType, {
        slug: sportevent.slug,
    });

    const homeAwayCompetitors = getHomeAwayCompetitors(
        competitors.map((competitor) => ({
            ...competitor,
            score: competitor.score || [],
        }))
    );

    const scoreBoardCompetitors = homeAwayCompetitors.map((competitor) => {
        const { name, type, logo, id } = competitor || {};

        return {
            name,
            type,
            logo,
            isServer: false,
            id,
        };
    });

    const isLive = status === SportEventStatus.Live;

    const totalScore = {
        home: getTotalScore(homeAwayCompetitors[0]),
        away: getTotalScore(homeAwayCompetitors[1]),
    };

    return {
        isLive,
        sportId,
        market,
        linkToMatch,
        sportType,
        isMobile,
        startTime,
        totalScore,
        isTopPromoTournament,
        bgContainerStyles,
        scoreBoardCompetitors,
    };
};

export default useSportEventBanner;
