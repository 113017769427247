import { FC, memo, useState } from 'react';
import cn from 'classnames';
import { Autoplay, Lazy, Pagination } from 'swiper';
import type { SwiperProps } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { SvgIconSize } from 'components/PackIcon';
import { ControlButtonDirection } from './constants';
import ControlButton from './ControlButton';

import 'swiper/css';

interface Props extends SwiperProps {
    sliders: JSX.Element[];
    height?: number;
    delay?: number;
    withControls?: boolean;
    slideClassName?: string;
    containerClassName?: string;
    controlsContainerClassName?: string;
    controlClassName?: string;
    controlIconClassName?: string;
    controlIconSize?: SvgIconSize;
    loop?: boolean;
}

const Carousel: FC<Props> = ({
    sliders,
    height,
    delay,
    withControls = true,
    slideClassName,
    containerClassName,
    controlsContainerClassName,
    controlClassName,
    controlIconClassName,
    controlIconSize,
    loop = true,
    ...swiperProps
}) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const onInit = (swiper: SwiperClass) => {
        setActiveIndex(swiper.activeIndex);
    };

    const onSlideChange = (swiper: SwiperClass) => {
        setActiveIndex(swiper.activeIndex);
    };

    return (
        <div
            style={{ height }}
            className={cn('relative', containerClassName)}
            data-test="carousel"
        >
            <Swiper
                slidesPerView="auto"
                loop={loop}
                lazy={{
                    loadPrevNext: true,
                    loadOnTransitionStart: true,
                }}
                autoplay={
                    delay ? { delay, disableOnInteraction: false } : false
                }
                modules={[Autoplay, Pagination, Lazy]}
                speed={600}
                touchStartPreventDefault={false}
                onSlideChange={onSlideChange}
                onInit={onInit}
                {...swiperProps}
            >
                {sliders.map((slide) => (
                    <SwiperSlide
                        key={slide.key}
                        className={cn('flex overflow-hidden', slideClassName)}
                        style={{ height }}
                        data-test="carousel__slide"
                    >
                        {slide}
                    </SwiperSlide>
                ))}
                {withControls && (
                    <div className={controlsContainerClassName}>
                        <ControlButton
                            type={ControlButtonDirection.Prev}
                            controlClassName={controlClassName}
                            controlIconClassName={controlIconClassName}
                            controlIconSize={controlIconSize}
                            slidesLength={sliders.length}
                            activeIndex={activeIndex}
                            loop={loop}
                            dataTest="carousel__arrow-prew"
                        />
                        <ControlButton
                            type={ControlButtonDirection.Next}
                            controlClassName={controlClassName}
                            controlIconClassName={controlIconClassName}
                            controlIconSize={controlIconSize}
                            slidesLength={sliders.length}
                            activeIndex={activeIndex}
                            loop={loop}
                            dataTest="carousel__arrow-next"
                        />
                    </div>
                )}
            </Swiper>
        </div>
    );
};

export default memo(Carousel);
