import dateFnsIsTomorrow from 'date-fns/isTomorrow';
import parseISO from 'date-fns/parseISO';
import { useTranslations } from 'next-intl';

import { useFormatTimeAndDate } from 'hooks';
import { useClientContext } from 'layouts/Root/ClientRenderContext';

type Formats = {
    dateFormat: string;
    timeFormat: string;
};

type StartMatchData = {
    time: string;
    date: string;
};

const useStartMatchData = (
    startTime: string,
    formats?: Partial<Formats>
): StartMatchData => {
    const t = useTranslations('betting-global.global');
    const isClientRouter = useClientContext();
    const { date, time, isToday } = useFormatTimeAndDate({
        timeStamp: startTime,
        formats,
    });

    if (!isClientRouter) {
        return { time, date };
    }

    const isTomorrow = dateFnsIsTomorrow(parseISO(startTime));

    let startDate = date;

    if (isToday) startDate = t('todayText');

    if (isTomorrow) startDate = t('tomorrowText');

    return { time, date: startDate };
};

export default useStartMatchData;
