import { SportType } from 'app-constants';
import { Sports } from 'components/betting/Categorizer/constants';

export const sportBackgroundIconMap: Record<string, string> = {
    [Sports.BASKETBALL]: 'basketball.svg',
    [Sports.TENNIS]: 'tennis.svg',
    [Sports.FOOTBALL]: 'soccer.svg',
    [Sports.ICE_HOCKEY]: 'ice-hockey.svg',
    [SportType.Esports]: 'cybersport_default.svg',
    [SportType.Sports]: 'classic_default.svg',
};

export const sportNameBackgroundMap: Record<string, string> = {
    [Sports.ICE_HOCKEY]: 'bg-turquoise-500',
    [Sports.TENNIS]: 'bg-khaki-500',
    [Sports.FOOTBALL]: 'bg-green-500',
    [Sports.BASKETBALL]: 'bg-yellow-500',
    [SportType.Esports]: 'bg-[#FF4800]',
    [SportType.Sports]: 'bg-violet-500',
};
